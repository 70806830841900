//
// events-report.scss
//

.events-report__charts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.events-report__chart {
  min-width: 300px;
  min-height: 200px;
  max-width: 50%;
  flex-grow: 1;

  @media (max-width: 700px) {
    max-width: 100%;
  }
}

.events-report__filter-date-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.events-report__filter-date {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.events-report__pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.events-report__pagination-button {
  border: none;
  padding: 0;
  background: white;
}

.events-report__pagination-button-disabled {
  color: grey;
}
