//
// rso-comments.scss
//

.rso-comments-header-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}

.checkbox-container {
  padding: 4px 4px 4px 0;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid lightgrey;
  border-radius: 4px;
}

.checkbox-rso-comments {
  width: 25px;
  border-color: lightgrey;
}

.rso-search-container {
  display: flex;
  gap: 4px;
  position: relative;

  .rso-search-btn-container {
    height: 30px;
    padding: 4px 10px 4px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #9748ff;
    border-bottom-width: 2px;
    border-radius: 7px;
  }

  .rso-search-input {
    padding: 0 10px;
    border: 1px solid lightgrey;
    border-radius: 4px;
  }

  .rso-remove-search-btn {
    position: absolute;
    right: 100px;
    top: 1px;
    width: 20px;
    height: 20px;
    border: none;
    color: grey;
    background-color: white;
  }
}
