﻿/*
 *  Document   : _dropdownmenu.scss
 *  Author     : RedStar Template
 *  Description: This scss file for dropdown menu style classes
 */
.dropdown-menu {
  @include border-radius(0);
  margin-top: -35px !important;
  margin-left: -15px;
  -webkit-box-shadow: 0 5px 15px 2px rgba(64, 70, 74, 0.2) !important;
  box-shadow: 0 5px 15px 2px rgba(64, 70, 74, 0.2) !important;
  border-radius: 0px;
  border: none;
  padding: 0px;

  .divider {
    margin: 5px 0;
  }

  .header {
    font-size: 13px;
    font-weight: bold;
    width: 100%;
    border-bottom: 1px solid #eee;
    text-align: center;
    padding: 4px 0 6px 0;
  }

  ul.menu {
    padding-left: 0;

    &.tasks {
      h4 {
        color: #333;
        font-size: 13px;
        margin: 0 0 8px 0;

        small {
          float: right;
          margin-top: 6px;
        }
      }

      .progress {
        height: 7px;
        margin-bottom: 7px;
      }
    }

    .icon-circle {
      width: 36px;
      height: 36px;
      @include border-radius(50%);
      color: #fff;
      text-align: center;
      display: inline-block;
      float: left;

      i {
        font-size: 18px;
        line-height: 36px !important;
      }
    }

    .msg-user {
      width: 44px;
      height: 44px;
      @include border-radius(50%);
      color: #fff;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      float: left;

      img {
        float: left;
      }
    }

    li {
      border-bottom: 1px solid #eee;

      &:last-child {
        border-bottom: none;
      }

      a {
        padding: 11px 11px;
        text-decoration: none;
        @include transition(0.5s);
        float: left;
        width: 100%;

        &:hover {
          background-color: #e9e9e9;
        }
      }
    }

    .menu-info {
      display: inline-block;
      position: relative;
      top: 3px;
      left: 10px;
      float: left;
      width: calc(100% - 45px);

      h4,
      .menu-title {
        margin: 0;
        font-size: 13px;
        color: #333;
        float: left;
        width: 100%;
        line-height: 1;
        font-weight: bold;
      }

      p,
      .menu-desc {
        margin: 0;
        font-size: 11px;
        color: #aaa;
        float: left;
        width: 100%;
        line-height: 20px;

        .material-icons {
          font-size: 13px;
          color: #aaa;
          position: relative;
          top: 3px;
          float: left;
          margin-right: 3px;
          height: 20px;
        }
      }
    }
  }

  .footer {
    a {
      text-align: center;
      border-top: 1px solid #eee;
      padding: 10px 0 5px 0;
      font-size: 13px;
      margin-bottom: -5px;
      color: #ff5e00;
      font-weight: 500;

      &:hover {
        background-color: transparent;
      }
    }
  }

  > li {
    > a {
      padding: 7px 18px;
      color: #666;
      @include transition(all 0.5s);
      font-size: 14px;
      line-height: 25px;
      display: block;

      &:hover {
        background-color: rgba(0, 0, 0, 0.075);
      }

      i.material-icons {
        float: left;
        margin-right: 7px;
        margin-top: 2px;
        font-size: 20px;
      }
    }
  }
}

.dropdown-content li > a,
.dropdown-content li > span {
  font-size: 13px;
  color: #636262;
}

.dropdown-animated {
  -webkit-animation-duration: 0.3s !important;
  -moz-animation-duration: 0.3s !important;
  -o-animation-duration: 0.3s !important;
  animation-duration: 0.3s !important;
}

.dropdown-menu.pull-right.show {
  position: absolute !important;
  left: auto !important;
  right: 0 !important;
  top: 50px !important;
  transform: none !important;
}
