//
// notifications.scss
//

.notification-create__form-container {
    width: 400px;

    @media (max-width: 600px) {
        width: 200px;
    }
}

.notification-create__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
}

.notification-create__field-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.notification-create__input {
    flex-grow: 1;
    border: none;
    border-bottom: 1px solid grey;
}

.notification-create__button {
    border: none;
    background: none;
    padding: 0;
}

.notification-create__licenseplate {
    color: black;
    margin: 0;
}

.notification-create__error {
    color: red;
}

.notification-create__input-error {
    border: 1px solid red;
}

.notification-create__input-success {
    border: 1px solid green;
}
