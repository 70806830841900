/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Rubik';
  src: url('assets/fonts/rubik/Rubik-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik-Bold';
  src: url('assets/fonts/rubik/Rubik-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: Poppins, sans-serif;
}

@import '~@circlon/angular-tree-component/css/angular-tree-component.css';

.icon_delete i {
  cursor: pointer;
  color: #f44336;
}

.icon_add i {
  cursor: pointer;
}

.btn_block {
  button {
    margin-right: 20px;
  }
}

// table
table {
  th.mat-header-cell {
    font-size: 12px;
    padding-left: 7px;
    padding-right: 7px;
  }

  td.mat-footer-cell {
    padding-left: 7px;
    padding-right: 7px;
  }

  th:first-child.mat-header-cell {
    padding-left: 20px;
  }

  td.mat-cell {
    font-size: 11px;
    padding: 3px 7px;
  }

  td:first-child.mat-cell {
    padding-left: 20px;
  }
}

.icons {
  cursor: pointer;
}

.textarea {
  overflow: hidden;
}

.fl-c {
  display: flex;
  flex-direction: column;
}

.fl-r {
  display: flex;
  flex-direction: row;
}

// отключение выделение текста
.unselectable {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  not supported by any browser */
}

.mat-dialog-container {
  display: flex !important;
  flex-direction: column !important;
}

.expand-tree {
  background: #fafafa;
  padding: 5px 10px;
  border-radius: 5px;
}
