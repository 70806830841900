//
// events.scss
//

.events-header-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}

.filter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.filter__select {
  outline: none;
  border: none;
  color: white;
  background: #fcb424;
  padding: 4px 10px;
  border-radius: 20px;
  appearance: none;
}

.filter__option {
  color: #fcb424;
  background: white;
}

.events-button-file {
  display: none;
}

.events-button-file-label {
  border: 1px solid #7455fc;
  border-radius: 7px;
  display: inline-block;
  padding: 1.5px 10px;
  cursor: pointer;
}
